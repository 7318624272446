import { formatAccountName } from '@plurix/ecom-pages/src/utils/formatAccountName'
import React from 'react'
import { NpmInstitutionalPage } from 'src/local_modules'

import pdf from '../../../static/institucional/politica_de_privacidade.pdf'
import { account } from '../../../store.config'
import { DownloadIcon } from '../../assets/DownloadIcon'
import useCmsInstitutional from '../../hooks/useCmsInstitutional'

import '../../styles/pages/cookiePolicy.scss'

const CookiePolicy = () => {
  const { cookiePolicyBanners } = useCmsInstitutional()
  const brand = formatAccountName(account)

  return (
    <NpmInstitutionalPage
      title="Politica de cookie"
      banners={cookiePolicyBanners}
    >
      <div className="cookiePolicy-container">
        <p>
          A {brand} reconhece que a sua privacidade é importante, e por isso
          queremos ser transparentes sobre como utilizamos cookies para melhorar
          a sua experiência. Nesta página, você será direcionado ao nosso Aviso
          de Cookies, onde explicamos detalhadamente como essas tecnologias
          funcionam, quais tipos de cookies utilizamos, e como você pode
          gerenciar suas preferências. Acesse o aviso completo para entender
          como protegemos suas informações e como você pode ter um maior
          controle sobre seus dados enquanto navega em nosso site.
        </p>
        <p>
          <a href={pdf} download="cookie-policy.pdf" type="application/pdf">
            <DownloadIcon />
            Baixar Documento Completo
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default CookiePolicy
